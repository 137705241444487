<template>
  <div class="contact">
    <div class="contact-landing">
      <div class="leaflet-custom">
        <LMap :zoom="zoom" :center="center">
          <LTileLayer :url="url"></LTileLayer>
          <LMarker :lat-lng="[43.171951,16.441687]"></LMarker>

        </LMap>
      </div>
      <div class="contact-overlay">
        <div class="contact-inner">
          <h1>Contact us</h1>
          <p id="line"></p>
          <h3>We'd love to hear from you!</h3>
          <div class="contact-details">
            <div class="adress">
              <h2>Adress</h2>
              <p>Jurja Matijevica 17, Hvar</p>
            </div>
            <div class="phone">
              <h2>Phone contact</h2>
              <div class="phone-inner">
                <img src="../assets/whatsapp.png" alt="">
                <img src="../assets/viber.png" alt="">
                <p><a href="tel:+385976750979">+385 97 675 0979 Sanja</a></p>
              </div>
              <div class="phone-inner">
                <img src="../assets/whatsapp.png" alt="">
                <img src="../assets/viber.png" alt="">
                <p><a href="tel:+385918963726">+385 91 896 3726 Office</a></p>
              </div>
            </div>
            <div class="email">
              <h2>E-mail</h2>
              <p><a href="mailto:sanja@hvar.house">sanja@hvar.house</a></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {LMap, LTileLayer, LMarker} from 'vue2-leaflet';

export default {
  metaInfo() {
    return {
      title: 'We Would Love To Hear From You | Contact Us',
      meta: [
        {name: "robots", content: "index,follow"},
        {
          name: 'description',
          content: 'If you would like to know where to stay in Hvar old town or you have any other questions about villas on old town. We are here for you.'
        }

      ],
      link: [
        {rel: 'favicon', href: 'favicon.ico'}
      ]
    }
  },

  name: 'MyAwesomeMap',
  components: {
    LMap,
    LTileLayer,
    LMarker,
  },

  data() {
    return {
      url: "https://{s}.tile.osm.org/{z}/{x}/{y}.png",
      zoom: 16,
      center: [43.1729, 16.4411],
      bounds: null
    };
  }
}
</script>